import { Button } from "antd";
import React, { useState } from "react";
import MailchimpModal from "./mailchimpModal";
import { BellFilled } from "@ant-design/icons";

export default function SubscriptionBanner() {
  const [visible, setVisible] = useState(false);

  return (
    <React.Fragment>
      <MailchimpModal {...{ visible, setVisible }} />

      <div className="lg:text-left text-center grid grid-cols-1 gap-6 lg:mt-10 py-6 w-full">
        <div
          style={{
            fontSize: "1.8rem",
            fontFamily: "Oxygen",
            fontWeight: "bold",
          }}
          className="mx-3"
        >
          Cadastre-se e receba em primeira mão as novidades e lançamentos da
          Bivver Empreendimentos
        </div>
        <div className="lg:col-span-1 flex">
          <Button
            onClick={() => setVisible(true)}
            style={{
              borderRadius: "8px",
              opacity: 1,
              color: "white",
              font: "normal normal 600 20px/24px Segoe UI",
            }}
            className="md:w-2/3 md:mx-auto btn-bivver h-24"
          >
            <BellFilled
              style={{
                fontSize: "42px",
                position: "relative",
                bottom: "8px",
              }}
            />
            RECEBER NOVIDADES
          </Button>
        </div>
      </div>
    </React.Fragment>
  );
}
