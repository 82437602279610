import React, { useState, useEffect, useContext } from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import api from "../api";
import { AuthContext } from "../context/GlobalContextProvider";
import { Button } from "antd";
import { navigate } from "gatsby";
import praia from "../images/praia.png";
import SubscriptionBanner from "../components/subscriptionBanner";
import {
  WhatsAppOutlined,
  PhoneOutlined,
  MailOutlined,
} from "@ant-design/icons";

function Contato() {
  const [page, setPage] = useState();
  const context = useContext(AuthContext);
  useEffect(() => {
    getPage();
  }, []);

  const getPage = async () => {
    const { data } = await api.get("/contato");
    setPage(data);
  };

  return (
    <Layout
      titlePage="Fale com a Bivver Empreendimentos"
      pageDesctiption="CONTATO"
      loading={!page}
    >
      <SEO
        keywords={[`Bivver`, `Empreendimentos`, `imobiliários`, `praia`, `prédios`]}
        title="Contato"
      />
      {context?.user && (
        <Button
          style={{ borderColor: "orange", color: "orange" }}
          danger
          onClick={() => navigate(`/edicao/contato`)}
        >
          Editar
        </Button>
      )}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mt-24">
        <div>
          <img src={praia} />
        </div>
        <div className="grid grid-cols-1 gap-16">
          <div>
            <p className="text-xl">
              Fale agora com um de nossos especialistas Bivver, tire suas
              dúvidas, conheça e se surpreenda!
            </p>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-16 mt-8">
              <a
                href="https://wa.me/554797873689"
                rel="noopener noreferrer"
                target="_blank"
              >
                <Button
                  style={{
                    borderRadius: "8px",
                    opacity: 1,
                    color: "white",
                    font: "normal normal 600 20px/24px Segoe UI",
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                    width: "100%",
                  }}
                  className="btn-bivver h-24"
                >
                  <WhatsAppOutlined
                    style={{
                      fontSize: "42px",
                    }}
                  />
                  WHATSAPP
                </Button>
              </a>
              <a href="tel:+5547997873689">
                <Button
                  style={{
                    borderRadius: "8px",
                    opacity: 1,
                    color: "white",
                    font: "normal normal 600 20px/24px Segoe UI",
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                    width: "100%",
                  }}
                  className="btn-bivver h-24"
                >
                  <PhoneOutlined
                    style={{
                      fontSize: "42px",
                      transform: "scale(-1,1)",
                    }}
                  />
                  LIGUE PARA NÓS
                </Button>
              </a>
            </div>
          </div>
          <SubscriptionBanner />
        </div>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "64px",
        }}
      >
        <h2
          className="md:w-1/3"
          style={{
            textAlign: "center",
            paddingBottom: "12px",
            borderBottomWidth: "1.5px",
            borderBottomColor: "black",
          }}
        >
          Onde estamos
        </h2>
      </div>
      <div className="lg:flex py-8 md:mx-10">
        <div className="mx-2 md:mx-4 w-full lg:w-3/5">
          <iframe
            width="100%"
            title="SoftinLocationMap"
            height="240"
            frameBorder="0"
            style={{ border: 0, display: "block" }}
            src={`https://maps.google.com/maps?q=${encodeURI(
              page?.endereco2
            )}&t=&z=15&ie=UTF8&iwloc=&output=embed`}
            allowFullScreen=""
          />
        </div>
        <div className="mx-2 md:ml-8 mt-5 lg:mt-0 lg:w-1/2 xl:w-2/4">
          <h2 className="mb-6">Sede comercial:</h2>
          <div className="flex mb-2 mt-8">
            <box-icon
              name="map"
              size="48px"
              style={{ position: "relative", bottom: "8px" }}
            ></box-icon>
            <h3 className="flex text-base items-center">{page?.endereco2}</h3>
          </div>
          <div className="flex gap-16 ml-3 mt-8">
            <div className="flex mb-2 gap-2">
              <WhatsAppOutlined className="text-lg" />
              <p className="flex text-lg items-center">{page?.telefone2}</p>
            </div>
            <div className="flex mb-2 gap-2">
              <MailOutlined className="text-lg" />
              <p className="flex text-lg items-center">{page?.email2}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="lg:flex py-8 md:mx-10">
        <div className="mx-2 md:mx-4 w-full lg:w-3/5">
          <iframe
            width="100%"
            title="SoftinLocationMap"
            height="240"
            frameBorder="0"
            style={{ border: 0, display: "block" }}
            src={`https://maps.google.com/maps?q=${encodeURI(
              page?.endereco
            )}&t=&z=15&ie=UTF8&iwloc=&output=embed`}
            allowFullScreen=""
          />
        </div>
        <div className="mx-2 md:ml-8 mt-5 lg:mt-0 lg:w-1/2 xl:w-2/4">
          <h2 className="mb-6">Base administrativa:</h2>
          <div className="flex mb-2 mt-8">
            <box-icon
              name="map"
              size="48px"
              style={{ position: "relative", bottom: "8px" }}
            ></box-icon>
            <h3 className="flex text-base items-center">{page?.endereco}</h3>
          </div>
          <div className="flex gap-16 ml-3 mt-8">
            <div className="flex mb-2 gap-2">
              <WhatsAppOutlined className="text-lg" />
              <p className="flex text-lg items-center">{page?.telefone}</p>
            </div>
            <div className="flex mb-2 gap-2">
              <MailOutlined className="text-lg" />
              <p className="flex text-lg items-center">{page?.email}</p>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "64px",
        }}
      >
        <h2
          className="md:w-1/3"
          style={{
            textAlign: "center",
            paddingBottom: "12px",
            borderBottomWidth: "1.5px",
            borderBottomColor: "black",
          }}
        >
          Redes sociais
        </h2>
      </div>
      <div
        className="mt-8"
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <a
          href="https://www.facebook.com/Bivver-Empreendimentos-109070777371707"
          rel="noopener noreferrer"
          target="_blank"
        >
          <i className="bx bxl-facebook -ml-3 text-6xl"></i>
        </a>
        <a
          href="https://www.instagram.com/bivver_empreendimentos"
          rel="noopener noreferrer"
          target="_blank"
        >
          <i className="bx bxl-instagram  text-6xl"></i>
        </a>
        <a
          href="https://www.linkedin.com/company/bivver-empreendimentos/"
          rel="noopener noreferrer"
          target="_blank"
        >
          <i className="bx bxl-linkedin ml-3 text-6xl"></i>
        </a>
      </div>
    </Layout>
  );
}

export default Contato;
