/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import Modal from "antd/lib/modal/Modal";
import PropTypes from "prop-types";
import { Form, Button, Input, message } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import { MaskedInput } from "antd-mask-input";

const Message = message;
export default function MailchimpModal({ visible, setVisible }) {
  // const listId = "ecd2b2b1cd";

  const [loadingSave, setLoadingSave] = useState(false);
  return (
    <Modal
      style={{ top: "10px" }}
      footer={null}
      visible={visible}
      closable={false}
      onCancel={() => setVisible(false)}
      destroyOnClose
      modalRender={(modal) => (
        <div
          style={{
            background: "#FFFFFF 0% 0% no-repeat padding-box",
            boxShadow: "0px 6px 12px #00000029",
            borderRadius: "8px",
            opacity: "1",
            padding: "3px",
          }}
        >
          {modal}
        </div>
      )}
    >
      <MailchimpSubscribe
        url={
          "https://bivver.us1.list-manage.com/subscribe/post?u=e170e1ceae76c3ae407818dcb&id=ecd2b2b1cd"
        }
        render={({ subscribe, status, message }) => {
          status === "sending" && setLoadingSave(true);
          status !== "sending" && setLoadingSave(false);
          if (status === "success") {
            Message.success(message);
            setVisible(false);
          }
          return (
            <div>
              <h2
                style={{
                  textAlign: "left",
                  font: "normal normal bold 28px/44px Oxygen !important",
                  letterSpacing: "0px",
                  color: "#000000",
                  opacity: 1,
                }}
              >
                Cadastre-se e fique por dentro das novidades
              </h2>
              <div
                className="mt-2"
                style={{
                  width: "100%",
                  textAlign: "left",
                  font: "normal normal normal 18px/32px Source Sans Pro",
                  letterSpacing: "0px",
                  color: "#000000",
                  opacity: 0.6,
                }}
              >
                Fique por dentro dos lançamentos, condições especiais e
                promoções exclusivas. É só preencher o formulário.
              </div>
              <br />
              <Form onFinish={subscribe}>
                <label
                  style={{
                    font: "normal normal 600 16px/32px Source Sans Pro",
                    letterSpacing: "0px",
                    color: "#000000",
                    opacity: 1,
                  }}
                >
                  Como você se chama?
                </label>
                <br />
                <Form.Item name="FNAME">
                  <Input
                    className="chimp-input"
                    bordered={false}
                    type="text"
                    name="FNAME"
                    placeholder="Digite seu nome completo"
                  />
                </Form.Item>
                <label
                  style={{
                    font: "normal normal 600 16px/32px Source Sans Pro",
                    letterSpacing: "0px",
                    color: "#000000",
                    opacity: 1,
                  }}
                >
                  Qual e-mail você mais usa?
                </label>
                <br />
                <Form.Item name="EMAIL">
                  <Input
                    className="chimp-input"
                    bordered={false}
                    type="email"
                    name="EMAIL"
                    placeholder="exemplo@exemplo.com"
                    required
                  />
                </Form.Item>
                <label
                  style={{
                    font: "normal normal 600 16px/32px Source Sans Pro",
                    letterSpacing: "0px",
                    color: "#000000",
                    opacity: 1,
                  }}
                >
                  Qual é o número do seu celular com DDD?
                </label>
                <br />
                <Form.Item name="PHONE">
                  <MaskedInput
                    className="chimp-input"
                    bordered={false}
                    type="tel"
                    name="PHONE"
                    id="celular"
                    placeholder="(99) 99999-9999"
                    mask="(11) 11111-1111"
                    required
                  />
                </Form.Item>
                {status === "error" && (
                  <div
                    className="mb-3"
                    style={{ color: "red" }}
                    dangerouslySetInnerHTML={{ __html: message }}
                  />
                )}
                <Form.Item>
                  <Button
                    className="h-16 btn-bivver"
                    style={{
                      borderRadius: "8px",
                      color: "#fff",
                      fontSize: "20px",
                      lineHeight: "1rem",
                      padding: "15px 24px",
                      width: "100%",
                    }}
                    disabled={loadingSave}
                    htmlType="submit"
                  >
                    {loadingSave && <LoadingOutlined />} RECEBER NOVIDADES
                  </Button>
                </Form.Item>
              </Form>
            </div>
          );
        }}
      />
    </Modal>
  );
}
MailchimpModal.propTypes = {
  visible: PropTypes.bool,
  setVisible: PropTypes.func,
};
